<template>
	<aside id="aside" class="aside">
		<div class="separator">
			<div class="cta">
				<h4 v-t="`pages[${index}].cta.title`" />
				<p v-t="`pages[${index}].cta.text`" />
				<router-link
					class="cta-button"
					:to="{ name: $t(`pages[${index}].cta.link.url`) }"
					v-ga="
						$ga.commands.trackClick.bind(
							this,
							'call_to_action',
							$t(`pages[${index}].cta.link.name`)
						)
					"
				>
					<font-awesome-icon
						:icon="['fal', $t(`pages[${index}].cta.link.icon`)]"
						fixed-width
					/>
					{{ $t(`pages[${index}].cta.link.name`) }}
				</router-link>
			</div>
			<svg class="waves" id="floaty-mcwaves-sepatator-2" viewBox="0 0 100 15">
				<path fill="#fdfdff" d="M0 30 V13 Q30 15 55 14 T100 11 V40z" />
			</svg>
		</div>
	</aside>
</template>

<script>
export default {
	props: {
		index: {
			type: Number,
			required: false
		}
	}
}
</script>

<style lang="scss">
@import '@/assets/variables.scss';

.aside {
	position: relative;
	.cta {
		position: relative;
		z-index: 3;
		padding: 4em 1em;
		color: $white;
		max-width: $max-width;
		margin: 15px auto;
	}
}
.separator {
	position: relative;
	min-height: 300px;
	background-image: $night;
	.waves:nth-child(3) {
		transform: rotate(180deg);
		top: -1px;
	}
}
.cta-button {
	display: inline-block;
	border-radius: 15px;
	padding: 1em;
	color: $white;
	background: $spring;
	box-shadow: $shadow;
	&:hover {
		color: inherit;
		box-shadow: $shadow-lg;
	}
}

@media (prefers-color-scheme: dark) {
	.aside {
		.cta {
			color: $white;
		}
	}
	.separator {
		background-image: $gold;
	}
	.cta-button {
		color: $white;
		background: $night;
	}
}
</style>
